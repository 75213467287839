/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 * See: https://github.com/jlengstorf/gatsby-theme-jason-blog/blob/master/src/components/SEO/SEO.js
 * TODO: rel canonical
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import SchemaOrg from './SchemaOrg';

function SEO({ title, description, slug, lang, meta, ogImg, fecha, isBlogPost }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
						author
						siteUrl
						image
          }
        }
      }
    `
  )

	const url = slug ? `${site.siteMetadata.siteUrl}${slug}` : site.siteMetadata.siteUrl;

	const metaTitle = title || site.siteMetadata.title
	const metaDescription = description || site.siteMetadata.description
	const image = ogImg ? `${site.siteMetadata.siteUrl}${ogImg}` : site.siteMetadata.image;

	const datePublished = isBlogPost ? fecha : false;

  return (
	<>
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      /* titleTemplate={`%s | anonimoconiglio.com`} */
			defer={false}
			link={[
				{
					rel: 'canonical',
					key: url,
					href: url,
				}
			]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
					content: metaTitle,
        },
				{
					property: `og:site_name`,
					content: site.siteMetadata.title,
				},
				{
					property: `og:url`,
					content: url,
				},
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
				{
					property: `og:image`,
					content: image,
				},
        {
          name: `twitter:card`,
					content: `summary_large_image`,
        },
				{
					name: `twitter:image`,
					content: image,
				},
        {
          name: `twitter:site`,
          content: `@anonimoconiglio`,
        },
				{
					name: `twitter:creator`,
					content: `@anonimoconiglio`,
				},
        {
          name: `twitter:title`,
					content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
		<SchemaOrg
			isBlogPost={isBlogPost}
			url={url}
			title={title}
			image={image}
			description={metaDescription}
			datePublished={datePublished}
			canonicalUrl={site.siteMetadata.siteUrl}
			author={site.siteMetadata.author}
			defaultTitle={site.siteMetadata.title}
		/>
	</>
  )
}

SEO.defaultProps = {
	isBlogPost: false,
  lang: `it`,
  meta: [],
  description: ``,
	ogImg: null,
	link: [],
}

SEO.propTypes = {
	isBlogPost: PropTypes.bool,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
	ogImg: PropTypes.string,
	link: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
